/**
 *	Table of Content
 *
 *  # Sidebar
 *	# Header
 *  # Content
 */

/**
 *  # Sidebar
 */
.site-sidebar {
  position: relative;
  width: 5rem;
  background: #ffffff;
  padding: 25px 0 20px;
  box-shadow: 0 2.5px 5px 0 rgba(0, 0, 0, 0.16);
  transition: all .3s;
}
.site-sidebar.open {
  width: 300px;
  padding: 25px 0;
}
.site-brand {
  text-align: center;
  padding: 10px;
  margin-bottom: 4rem;
}
.site-brand img {
  width: auto;
  height: 50px;
}
.site-navigation {
  height: calc( 100vh - 3.125rem - 4.375rem - 4rem );
  overflow-x: hidden;
  overflow-y: scroll;
}
.site-navigation::-webkit-scrollbar {
  width: 5px;
}
.site-navigation::-webkit-scrollbar-track {
  background: transparent;
}
.site-navigation::-webkit-scrollbar-thumb {
  border-radius: 6.25rem;
  background: rgba(0, 0, 0, .05);
}
.site-navigation::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, .1);
}
.site-navigation .nav-item {
  position: relative;
  color: #00b200;
  border-radius: 5px;
}
.site-sidebar .nav-item.active:after,
.site-sidebar .nav-item.dropdown.show:after,
.site-sidebar .nav-item:hover:after,
.site-sidebar .nav-item .nav-link.active:after {
  content: '';
  position: absolute;
  top: 0;
  left: -1rem;
  display: block;
  width: 5px;
  height: 2.75rem;
  background: #00b200;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.site-sidebar.open .nav-item.active:after,
.site-sidebar.open .nav-item.dropdown.show:after,
.site-sidebar.open .nav-item:hover:after,
.site-sidebar .nav-item .nav-link.active:after {
  left: -20px;
}
.site-navigation .nav-item.active,
.site-navigation .nav-item.dropdown.show,
.site-navigation .nav-item:hover {
  background: #00b200;
}
.site-navigation .nav-item.active .nav-link,
.site-navigation .nav-item.dropdown.show .nav-link,
.site-navigation .nav-item:hover .nav-link,
.site-navigation .dropdown-menu .nav-item {
  color: #ffffff;
}
.site-sidebar .site-navigation .navbar-text {
  display: none;
}
.site-sidebar.open .site-navigation .navbar-text {
  display: inline-block;
}
.site-sidebar .nav-link .material-icons {
  margin-right: 0;
}
.site-sidebar.open .nav-link .material-icons {
  margin-right: 10px;
}
.site-sidebar .nav-link:after {
  display: none;
}
.site-sidebar.open .nav-link:after {
  display: inline-block;
}
.site-sidebar .nav > .nav-item {
  margin: 0 auto .25rem;
}
.site-sidebar.open .nav > .nav-item {
  margin: 0 20px .25rem;
}
.site-sidebar .nav > .nav-item .nav-link {
  padding: 10px;
}
.site-sidebar:not(.open) .show.dropdown .dropdown-menu {
  display: none !important;
}

/**
 *  # Header
 */
.site-header {
  background: #ffffff;
  color: #000000;
  padding: .688rem 1rem;
  border-radius: 5px;
  margin: 25px;
  box-shadow: 0 2.5px 5px 0 rgba(0, 0, 0, 0.16);
}
.site-header .notification-section {
  cursor: pointer;
}
.site-header .notification-section .material-icons,
.site-header .chat-section .material-icons {
  font-size: 1.5rem;
  line-height: 1;
}
.site-header .chat-section {
  line-height: 1;
}
.site-header .notification-section .dropdown-toggle:after {
  display: none;
}
.site-header a,
.site-header button {
  box-shadow: none !important;
}
.site-header a:hover,
.site-header button:hover {
  color: #00b200;
}
.site-header .dropdown-menu {
  margin-top: 1rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.site-header .profile-section .dropdown-menu {
  margin-top: .6875rem;
}

/**
 *  # Content
 */
.site-content {
  height: calc( 100vh - ( 25px * 2 ) - 4rem );
  padding: 0 25px 25px;
  overflow-x: hidden;
  overflow-y: auto;
}
.site-content::-webkit-scrollbar {
  width: 5px;
}
.site-content::-webkit-scrollbar-track {
  background: transparent;
}
.site-content::-webkit-scrollbar-thumb {
  border-radius: 6.25rem;
  background: #dedede;
}
.site-content::-webkit-scrollbar-thumb:hover {
  background: #bbbbbb;
}