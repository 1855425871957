/**
 *	Table of Content
 *
 *	# General
 *    # Alert
 *    # Analytic
 *    # Avatar
 *	  # Badge
 *		# Border
 *		# Button
 *		# Card
 *		# Color
 *		# Datepicker
 *    # Dropdown
 *		# Form
 *		# Graphic
 *		# Link
 *		# List
 *		# Loading Screen
 *    # Media
 *    # Modal
 *    # Navbar
 *    # Pagination
 *    # Progress
 *    # Rotate
 *    # Row
 *    # Shadow
 *		# Tables
 *		# Tabs
 *		# Typography
 *    # Width
 */

/**
 *	# General
 */
html,
body {
  min-height: 100vh;
}
body {
  font-size: .875rem;
  font-family: "Poppins", sans-serif;
  letter-spacing: -.5px;
  line-height: 1.5;
  background: #f3f3f3;
  color: #878787;
  overflow: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
#root,
.page-content {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  height: 100vh;
}
.site-sidebar + .site-main {
  width: calc(100vw - 80px);
  background: #fbfafc;
  transition: all .2s ease-in-out;
}
.site-sidebar.open + .site-main {
  width: calc(100vw - 300px);
  background: #fbfafc;
}

/**
 *	# Alert
 */
.alert {
  font-size: .875rem;
  border-radius: .3125rem;
  padding: 1rem;
  line-height: 1;
}
.alert-light {
  border-color: rgba( 0, 0, 0, .05 );
}

/**
 *	# Analytic
 */
.all-opd-analytic {
  border-radius: .3125rem !important;
}
.all-opd-analytic > div {
  width: calc( 100% / 6 );
}

/**
 *	# Avatar
 */
.profile-section {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}
.profile-section .dropdown-toggle:after {
  display: none;
}
.profile-section .profile-avatar {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.profile-section .avatar-section {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.profile-section .avatar-section h6 {
  font-size: 14px;
  margin-bottom: 2.5px;
}
.profile-section .avatar-section .media-body {
  text-align: right;
  color: #707070;
  font-size: .75rem;
  margin-right: 15px;
}
.profile-section .avatar-section .material-icons {
  font-size: 2.5rem;
}
.profile-section .dropdown {
  margin-left: 10px;
}
.avatar {
  display: block;
}
.avatar .avatar-img {
  display: block;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.avatar .material-icons {
  font-size: 45px;
}

/**
 *	# Badge
 */
.badge {
  font-size: .625rem;
  font-weight: 400;
  line-height: 1;
  border-radius: .3125rem;
  padding-left: .625rem;
  padding-right: .625rem;
}

/**
 *	# Border
 */
.border,
.border-top,
.border-right,
.border-bottom,
.border-left {
  border-color: #e4e4e4 !important;
}
.border-green {
  border-color: #00b200 !important;
}

/**
 *	# Button
 */
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .875rem;
  line-height: 1.4;
  border-radius: .3125rem;
  padding: .5625rem 1rem;
  box-shadow: none;
  outline: 0;
  background-color : #f9f9f9;
  color: black;
  border-color: #e4e4e4;
}
.btn:focus {
  box-shadow: none;
}
button.close,
a.close {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  outline: 0 !important;
  box-shadow: none !important;
}
.btn-pill {
  border-radius: 6.25rem;
}
.btn-transparent {
  background: transparent !important;
  border: 0;
}
.btn-accent-gradient {
  color: #ffffff;
  box-shadow: 0 2.5px 5px 0 rgba(0, 0, 0, 0.16) !important;
  background-image: linear-gradient(259deg, rgba(0,176,155,1) 0%, rgba(150,201,61,1) 100%);
  border: 0;
  line-height: 1.7;
  padding: .5rem 1rem;
}
.btn-accent-gradient:hover {
  color: #ffffff;
}
.btn-white {
  border-color: #ffffff !important;
  background: #ffffff !important;
  color: #707070 !important;
}

/**
 *	# Card
 */
.card {
  border: 0;
  border-radius: .3125rem;
  margin-bottom: 1.25rem;
  background: #ffffff;
  box-shadow: 0 2.5px 5px 0 rgba( 0, 0, 0, .16 );
}
.card:last-child {
  margin-bottom: 0;
}
.card-header {
  background: transparent;
  border-bottom: 0;
  padding: 1rem;
}
.card-body {
  background: transparent;
  padding: 1rem;
}
.card-footer {
  background: transparent;
  border-top: 0;
  padding: 1rem;
}
.card-title {
  font-size: 1rem;
  margin-bottom: 1rem;
}
.card-subtitle {
  font-size: .875rem;
  margin-top: 0;
  margin-bottom: 1rem;
}
.card-title + .card-subtitle {
  margin-top: -.875rem;
}
/**
 *	# Circular Progressbar
 */


/**
 *	# Color
 */
.text-green {
  color: #00b200 !important;
}




/**
 *	# Datepicker
 */
.datepicker.dropdown-menu {
  padding: .5rem;
  border-radius: .3125rem;
  box-shadow: 0 2.5px 5px 0 rgba( 0, 0, 0, .16 );
}
.datepicker-dropdown:before {
  display: none;
}
.datepicker table {
  border-spacing: .125rem;
  border-collapse: separate;
}
.datepicker td,
.datepicker th {
  width: 2rem;
  height: 2rem;
  border-radius: .3125rem;
}
.datepicker table tr td span {
  height: 3rem;
  line-height: 3rem;
  border-radius: .3125rem;
  margin: .125rem;
}
.datepicker table tr td.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active:hover,
.datepicker table tr td span.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active:hover {
  background-image: linear-gradient(259deg, rgba(0,176,155,1) 0%, rgba(150,201,61,1) 100%);
  text-shadow: none;
}

/**
 *	# Dropdown
 */
.dropdown {
  cursor: pointer;
}
.dropdown-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropdown-menu {
  padding: .5rem 0;
  margin: 0;
  border: 0;
  box-shadow: 0 2.5px 5px 0 rgba( 85, 85, 85, 0.05 );
}
.dropdown-header {
  font-size: .875rem;
  font-weight: 600;
  padding: .5rem 1rem;
}
.dropdown-item {
  font-size: .875rem;
  padding: .5rem 1rem;
  outline: none !important;
}
.dropdown-divider {
  margin: .5rem 0;
}
.dropdown-toggle:after {
  content: '\e5cc';
  font-family: 'Material Icons';
  margin-left: 0;
  border: 0 !important;
  transform: rotate( -90deg );
}
.dropdown.show > .dropdown-toggle:after {
  transform: rotate( 90deg );
}
.dropdown.show > .dropdown-menu {
  display: block;
  float: none;
}
.dropdown-menu .dropdown-toggle:after {
  display: none !important;
}

/**
 *	# Form
 */
input,
select,
textarea {
  outline: 0 !important;
}
legend,
label {
  font-size: .875rem;
  font-weight: 600;
  margin-bottom: .5rem;
}
.form-group,
.form-check {
  margin-bottom: 1rem;
}
.form-group .form-check {
  margin-bottom: 0;
}
.form-check-label {
  display: flex;
  align-items: center;
  font-weight: 400;
}
.form-check-input {
  margin-top: 0;
}
.form-control {
  z-index: 3;
  font-size: .875rem;
  height: auto;
  padding: .5rem 1rem;
  border-radius: .3125rem;
  border-color: rgba( 0, 0, 0, .1 );
  background: #f9f9f9;
  appearance: none;
}
.form-control:active,
.form-control:focus,
.form-control:read-only {
  border-color: rgba( 0, 0, 0, .1 );
  background: #f9f9f9;
  box-shadow: none;
}
.form-control::-webkit-input-placeholder {
  color: #707070;
}
.form-control:-ms-input-placeholder {
  color: #707070;
}
.form-control::placeholder {
  color: #707070;
}
textarea.form-control {
  min-height: 6.25rem;
}
.input-group.input-group-pill .form-control:first-child,
.input-group.input-group-pill .input-group-prepend:first-child .input-group-text,
.input-group.input-group-pill .input-group-prepend:first-child .btn {
  border-top-left-radius: 6.25rem;
  border-bottom-left-radius: 6.25rem;
}
.input-group.input-group-pill .form-control:last-child,
.input-group.input-group-pill .input-group-append:last-child .input-group-text {
  border-top-right-radius: 6.25rem;
  border-bottom-right-radius: 6.25rem;
}
.input-group-text {
  font-size: .875rem;
  line-height: 1;
  padding: .5rem .625rem;
  border-color: rgba( 0, 0, 0, .1 );
  background: #f9f9f9;
}
.input-group .form-control:first-child {
  border-right-color: transparent;
}
.input-group .form-control:last-child {
  border-left-color: transparent;
}
.input-group .form-control:nth-child(2):not(:last-child) {
  border-right-color: transparent;
  border-left-color: transparent;
}
.input-group .btn-accent-gradient {
  box-shadow: none !important;
}
.input-group .select-picker {
  flex: 1;
}
.select-picker > [class*="-menu"] {
  z-index: 10;
}
.input-group-prepend + .select-picker > div:first-child,
.input-group-prepend + .select-picker > div:nth-child(2) {
  border-radius: 0 5px 5px 0;
}
.input-group .select-picker:nth-child(2):not(:last-child) > div:first-child,
.input-group .select-picker:nth-child(2):not(:last-child) > div:nth-child(2) {
  border-radius: 0;
}
.input-group .css-b8ldur-Input {
  padding-top: 4px;
  padding-bottom: 4px;
}

/**
 *	# Graphic
 */
img {
  width: 100%;
  height: auto;
}
.graphic-table {
  border-radius: .3125rem !important;
}
.graphic-table > div {
  width: calc( 100%);
}
.image-uploader {
  position: relative;
}
.image-preview {
  width: 120px;
  height: 120px;
  border-radius: 100%;
  background-color: #ffffff;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 auto;
  box-shadow: 0 2.5px 5px 0 rgba(0, 0, 0, 0.16);
}
.image-upload-field {
  position: absolute;
  visibility: hidden;
}
.image-upload-trigger {
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 100%;
  background: #ffffff;
  box-shadow: 0 2.5px 5px 0 rgba(0, 0, 0, 0.16);
}
.image-upload-trigger i {
  line-height: 40px;
}


/**
 *	# Link
 */
a,
.btn-link {
  color: inherit;
  text-decoration: none !important;
  outline: none !important;
  transition: all .3s ease-in-out;
}
a:hover,
.btn-link:hover {
  color: #00b200;
}

/**
 *	# List
 */
.list-group-item {
  font-size: .875rem;
  padding: .5rem 1rem;
  line-height: 1.15;
  border: 1px solid rgba( 0, 0, 0, .1 );
}

/**
 *	# Loading Screen
 */
.loading-screen {
  position: fixed;
  z-index: 5;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(255, 255, 255, .5);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}
.loading-screen .spinner-grow {
  width: 60px;
  height: 60px;
  margin-bottom: 30px;
}
.loading-screen h4 {
  font-size: 16px;
}

/**
 *	# Media
 */
.media {
  margin-bottom: 1rem;
}
.media .media {
  margin-top: 1rem;
  margin-bottom: 0;
}
.media-left {
  margin-right: 1rem;
}
.media-right {
  margin-left: 1rem;
}
.media-middle {
  align-self: center;
}
.media-bottom {
  align-self: flex-end;
}

/**
 *	# Modal
 */
.modal-header {
  align-items: center;
  border-bottom: 0;
}
.modal-header .close {
  margin: 0;
  padding: 0 .5rem;
  width: auto;
  height: auto;
  font-weight: 500;
  color: #a0a0a0;
  text-shadow: none;
  outline: 0 !important;
}
.modal-content {
  border: 0;
  border-radius: .3125rem;
  box-shadow: 0 2.5px 5px 0 rgba(0, 0, 0, .05);
}
.modal-footer {
  border-top: 0;
}
.modal-backdrop {
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 1);
}

/**
 *	# Navbar
 */
.navbar {
  padding: .5rem 1rem;
}
.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}
.nav-item {
  margin-bottom: .25rem;
  transition: all .3s ease-in-out;
}
.nav-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: 0;
  background: transparent;
  color: #00b200;
  font-size: .875rem;
  appearance: none;
  outline: 0 !important;
  transition: all .3s ease-in-out;
}
.nav-link span {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-link .material-icons {
  font-size: 1.5rem;
  line-height: 1;
}
.navbar-text {
  padding-top: 0;
  padding-bottom: 0;
}
.nav-item .dropdown-menu {
  position: static !important;
  background: transparent;
  padding: 0;
  list-style-type: none;
  width: 100%;
  box-shadow: none;
  transform: none !important;
}
.nav-item .dropdown-menu .nav-link {
  padding-left: 2.75rem !important;
}
.nav-item .dropdown-menu .dropdown-menu .nav-link {
  padding-left: 3.75rem !important;
}

/**
 *	# Pagination
 */
.pagination {
  margin-bottom: 1rem;

}
.pagination .page-link {
  width: 2.125rem;
  height: 2.125rem;
  line-height: 2rem;
  padding: 0;
  text-align: center;
}

/**
 *	# Progress
 */
.progress {
  font-size: .625rem;
  margin-bottom: 1rem;
}

/**
 *	# Rotate
 */
.rotate-90 {
  transform: rotate(90deg);
}
.rotate-180 {
  transform: rotate(180deg);
}
.rotate-270 {
  transform: rotate(270deg);
}

/**
 *	# Row
 */
.row {
  align-items: center;
  margin-right: -.5rem;
  margin-left: -.5rem;
}
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  padding-right: .5rem;
  padding-left: .5rem;
}

/**
 *  # Shadow
 */
.shadow {
  box-shadow: 0 2.5px 5px 0 rgba( 0, 0, 0, .16 ) !important;
}

/**
 *	# Tables
 */
.table,
table {
  width: 100%;
}
.table th,
.table td {
  padding: .5rem 1rem;
  vertical-align: middle !important;
}
.table thead th {
  font-weight: 500;
  color: #707070;
  border-top-width: 0;
  border-bottom-width: 1px;
  outline: 0 !important;
  cursor: pointer;
  text-align: left;
}
.table tbody th,
.table tbody td {
  color: #999999;
  text-align: left;
}
.table-hover tbody tr:hover {
  color: #707070;
  background-color: rgba(0, 0, 0, .03);
}
.table .sorting,
.table .sorting_asc,
.table .sorting_desc {
  position: relative;
}
.table .sorting:after,
.table .sorting_asc:after,
.table .sorting_desc:after {
  position: absolute;
  top: 50%;
  right: .125rem;
  content: '\e5cc';
  font-family: 'Material Icons';
  transform: translateY( -50% ) rotate( 90deg );
}
.table .sorting_asc:after {
  transform: translateY( -50% ) rotate( -90deg );
}
.table.dataTable {
  width: 100% !important;
}
.dataTables_length select {
  margin: 0 .5rem;
}
.dataTables_length label {
  margin-bottom: 0;
}
.dataTables_paginate {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dataTables_paginate .paginate_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  overflow: hidden;
  text-indent: -999px;
  cursor: pointer;
}
.dataTables_paginate .paginate_button.previous {
  margin-right: .5rem;
}
.dataTables_paginate .paginate_button:after {
  display: block;
  content: '\e5cc';
  font-size: 1.5rem;
  font-family: 'Material Icons';
  text-indent: 0;
}
.dataTables_paginate .paginate_button.previous:after {
  transform: rotate( -180deg );
}
.dataTable .dropdown .dropdown-toggle:after {
  display: none;
}


/**
 *	# Tabs
 */
.nav-tabs {
  display: flex;
  justify-content: center;
}
.nav-tabs .nav-item {
  flex: 1;
  text-align: center;
}
.tab-content {
  background-color: #ffffff;
  padding: 1.25rem;
}

/**
 *	# Typography
 */
small {
  font-size: .75rem;
}
p {
  margin-bottom: 1rem;
}
p:last-child {
  margin-bottom: 0;
}
.font-12 {
  font-size: .75rem !important;
}
.font-14 {
  font-size: .875rem !important;
}
.font-16 {
  font-size: 1rem !important;
}

/**
 *  # Width
 */
.element-min-width {
  min-width: 6.25rem;
}

.total{
  color :#ffffff;
  justify-content: flex-end;
}
.total:hover{
color:#00b200;
display: flex;
align-items: flex-end;
}