// Add bootstrap style as default css framework
@import 'node_modules/bootstrap/scss/bootstrap';

// Google fonts
@import url(https://fonts.googleapis.com/css?family=Poppins:400italic,600italic,400,600);

// Add material icon as default font icon
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

// Add bootstrap datepicker style
@import 'node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker.min';

// Admin panel styles
@import './assets/scss/general.scss';
@import './assets/scss/layout.scss';
